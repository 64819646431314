import React from 'react';
import { useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { List, Box, makeStyles } from '@material-ui/core';
import NavItem from './NavItem';
import Repeater from './Repeater';
import useAuth from 'src/hooks/useAuth';

import {
    isDigitalMkt,
    isRockstar,
    isAdmin,
    isSuper,
    isGeneralManager,
    isUser,
    isSalesManager,
} from 'src/utils/AuthRoles';
import { FLEET_LEAD_LIST_ROUTE } from 'src/routing/leadsFleet';
import { isPluginAvailable } from 'src/utils/store/isPluginAvailable';
import {
    CALLS_PLUGIN,
    FB_PLUGIN,
    MAILING_PLUGIN,
    WSP_PLUGIN,
} from 'src/constants/plugins';

function renderNavItems({
    items,
    pathname,
    depth = 0,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
    user,
}) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({
                        acc,
                        item,
                        pathname,
                        depth,
                        user,
                        isFavoriteActive,
                        favoriteRoutes,
                        setFavoritesRoutes,
                    }),
                []
            )}
        </List>
    );
}

export const LEAD_MANAGEMENT_ROUTES = [
    '/management/showroom-leads',
    '/management/bdc',
    '/management/leads',
    '/management/retention',
    FLEET_LEAD_LIST_ROUTE,
];

//?Special Routes Are Routes that need permission of an addon to display in menu
const specialRoutes = [
    '/conversations',
    '/management/recording',
    ...LEAD_MANAGEMENT_ROUTES,
];

const LEAD_ROUTES_AREAS = {
    ['/management/bdc']: 'bdc',
    ['/management/leads']: 'digital',
    ['/management/retention']: 'retention',
    ['/management/showroom-leads']: 'showroom',
    [FLEET_LEAD_LIST_ROUTE]: 'fleet',
};

function reduceChildRoutes({
    acc,
    pathname,
    item,
    depth,
    user,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
}) {
    const key = item.title + depth + Math.random();

    if (item.items) {
        var open = false;
        item.items.forEach((item) => {
            if (item.items) {
                item.items.forEach((item2) => {
                    if (item2.href && pathname.includes(item2.href)) {
                        open = true;
                    }
                });
            } else {
                if (item.href && pathname.includes(item.href)) {
                    open = true;
                }
            }
        });

        if (item.items.length !== 1) {
            ////END LOGIC
            acc.push(
                <NavItem
                    isFavoriteActive={isFavoriteActive}
                    favoriteRoutes={favoriteRoutes}
                    setFavoritesRoutes={setFavoritesRoutes}
                    depth={depth}
                    icon={item.icon}
                    info={item.info}
                    key={key}
                    open={Boolean(open)}
                    title={item.title}
                >
                    {renderNavItems({
                        depth: depth + 1,
                        pathname,
                        items: item.items,
                        isFavoriteActive,
                        favoriteRoutes,
                        setFavoritesRoutes,
                        user,
                    })}
                </NavItem>
            );
        }
    } else {
        if (item.title === 'add-section') return acc;

        let show = false;

        switch (item.href) {
            case '/conversations':
                if (
                    user &&
                    user.tier &&
                    ((user.stores &&
                        (isPluginAvailable(user.stores, WSP_PLUGIN) ||
                            isPluginAvailable(user.stores, FB_PLUGIN))) ||
                        isRockstar(user.tier._id) ||
                        ((isSuper(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.group &&
                            (isPluginAvailable(user.group.stores, WSP_PLUGIN) ||
                                isPluginAvailable(
                                    user.group.stores,
                                    FB_PLUGIN
                                ))))
                ) {
                    show = true;
                }
                break;
            case '/management/recordings':
                if (
                    user &&
                    user.tier &&
                    ((user.stores &&
                        isPluginAvailable(user.stores, CALLS_PLUGIN)) ||
                        isRockstar(user.tier._id) ||
                        ((isSuper(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.group &&
                            isPluginAvailable(user.group.stores, CALLS_PLUGIN)))
                ) {
                    show = true;
                }
                break;
            case '/management/lists':
                if (
                    user &&
                    user.stores &&
                    user.tier &&
                    (isPluginAvailable(user.stores, MAILING_PLUGIN) ||
                        isRockstar(user.tier._id) ||
                        ((isSuper(user.tier._id) ||
                            isDigitalMkt(user.tier._id)) &&
                            user.group &&
                            isPluginAvailable(
                                user.group.stores,
                                MAILING_PLUGIN
                            )))
                ) {
                    show = true;
                }
                break;
            case '/management/bdc':
            case '/management/showroom-leads':
            case '/management/leads':
            case '/management/retention':
            case FLEET_LEAD_LIST_ROUTE:
                if (
                    user &&
                    user.tier &&
                    (isRockstar(user.tier._id) ||
                        isSuper(user.tier._id) ||
                        isGeneralManager(user.tier._id) ||
                        isSalesManager(user.tier._id) ||
                        ((isUser(user.tier._id) || isAdmin(user.tier._id)) &&
                            user.areas &&
                            user.areas.includes(LEAD_ROUTES_AREAS[item.href])))
                ) {
                    show = true;
                }
                break;
            default:
                break;
        }

        if (
            !specialRoutes.includes(item.href) ||
            (specialRoutes.includes(item.href) && show)
        ) {
            acc.push(
                <NavItem
                    isFavoriteActive={isFavoriteActive}
                    favoriteRoutes={favoriteRoutes}
                    setFavoritesRoutes={setFavoritesRoutes}
                    depth={depth}
                    href={item.href}
                    icon={item.icon}
                    info={item.info}
                    open={true}
                    key={key}
                    title={item.title}
                />
            );
        }
    }

    return acc;
}

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const Barra = ({
    onMobileClose,
    menu,
    openMobile,
    isFavoriteActive,
    favoriteRoutes,
    setFavoritesRoutes,
    ...rest
}) => {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useAuth();

    return (
        <Box display="flex" flexDirection="column" className={classes.root}>
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Repeater
                    isFavoriteActive={isFavoriteActive}
                    favoriteRoutes={favoriteRoutes}
                    setFavoritesRoutes={setFavoritesRoutes}
                    renderNavItems={renderNavItems}
                    data={menu}
                    location={location}
                    user={user}
                />
            </PerfectScrollbar>
        </Box>
    );
};

export default Barra;
