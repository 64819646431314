export const FLEET_LEAD_TYPE = 'fleet';
export const DIGITAL_LEAD_TYPE = 'digital';
export const SHOWROOM_LEAD_TYPE = 'showroom';
export const BDC_LEAD_TYPE = 'bdc';
export const SERVICE_LEAD_TYPE = 'servicio';
export const HYP_LEAD_TYPE = 'hyp';
export const SPARE_PARTS_LEAD_TYPE = 'refacciones';
export const NEWS_LEAD_TYPE = 'nuevos';
export const PREOWNEDS_LEAD_TYPE = 'seminuevos';
export const COMPLAINTS_TYPE = 'complaints';
export const RETENTION_LEAD_TYPE = 'retention';

export type TAreas =
    | typeof DIGITAL_LEAD_TYPE
    | typeof SHOWROOM_LEAD_TYPE
    | typeof BDC_LEAD_TYPE
    | typeof SERVICE_LEAD_TYPE
    | typeof HYP_LEAD_TYPE
    | typeof SPARE_PARTS_LEAD_TYPE
    | typeof NEWS_LEAD_TYPE
    | typeof PREOWNEDS_LEAD_TYPE
    | typeof FLEET_LEAD_TYPE
    | typeof COMPLAINTS_TYPE
    | typeof RETENTION_LEAD_TYPE;

export const WARBOX_AREAS: TAreas[] = [
    NEWS_LEAD_TYPE,
    PREOWNEDS_LEAD_TYPE,
    SHOWROOM_LEAD_TYPE,
    BDC_LEAD_TYPE,
    SERVICE_LEAD_TYPE,
    SPARE_PARTS_LEAD_TYPE,
    HYP_LEAD_TYPE,
    FLEET_LEAD_TYPE,
    COMPLAINTS_TYPE,
    RETENTION_LEAD_TYPE,
];
